// Access it via ValueStore.instance()
export class ValueStore {
    constructor() {
        this.data = {};
    }
    static instance() {
        if (window._ds_value_store == null) {
            window._ds_value_store = new ValueStore();
        }
        return window._ds_value_store;
    }
    setValue(key, value) {
        this.data[key] = value;
    }
    getValue(key, defaultValue = null) {
        var _a;
        return ((_a = this.data[key]) !== null && _a !== void 0 ? _a : defaultValue);
    }
    // Returns value for key. Uses defaultValue if not value is present and also stores it.
    getOrPut(key, defaultValue) {
        if (this.getValue(key) == null) {
            this.setValue(key, defaultValue());
        }
        return this.getValue(key);
    }
}
